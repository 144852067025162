import { LogoutOutlined } from "@ant-design/icons";
import { Button, Layout, Menu, MenuProps, Switch } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { clearUser, selectUser } from "../redux/api/authSlice";

const { Header } = Layout;

type Props = {
  isDarkMode: boolean;
  setIsDarkMode: React.Dispatch<React.SetStateAction<boolean>>;
  toggleTheme: (checked: boolean) => void;
};

const items1: MenuProps["items"] = ["1"].map((key) => ({
  key,
  label: `nav ${key}`,
}));

export const HeaderComponent = ({
  isDarkMode,
  setIsDarkMode,
  toggleTheme,
}: Props) => {
  const { user } = useSelector(selectUser);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    dispatch(clearUser());
    navigate("/login");
  };

  return (
    <Header style={{ display: "flex", alignItems: "center" }}>
      <div className="demo-logo " style={{ color: "#fff" }}>
        Hello, {user.user?.user_fullname}
      </div>
      <Menu
        theme="dark"
        mode="horizontal"
        defaultSelectedKeys={["2"]}
        items={items1}
        style={{ flex: 1, minWidth: 0 }}
      />

      <Switch
        checkedChildren="Dark"
        unCheckedChildren="Light"
        checked={isDarkMode}
        onChange={toggleTheme}
      />

      {user.user ? (
        <Button
          style={{ marginLeft: 20 }}
          type="primary"
          icon={<LogoutOutlined />}
          onClick={handleLogout}
        >
          Logout
        </Button>
      ) : (
        <></>
      )}
    </Header>
  );
};
