import { api } from "../../../redux/api/api";

export const userEndpoints = api.injectEndpoints({
  endpoints: (builder) => ({
    getCategories: builder.query<
      {
        id: number;
        name: string;
        img: string;
        is_active: number;
      }[],
      string
    >({
      query: (query) => `/products/category?${query}`,
    }),

    createCategory: builder.mutation<any, { name: string; img: string }>({
      query: (body) => ({
        url: "/products/category",
        method: "POST",
        body,
      }),
    }),

    updateCategory: builder.mutation<
      any,
      { pay: { name: string; img: string }; id: number }
    >({
      query: (body) => ({
        url: `/products/category/${body.id}`,
        method: "PUT",
        body: body.pay,
      }),
    }),

    deleteCategory: builder.mutation<any, number>({
      query: (id) => ({
        url: `/products/category/${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetCategoriesQuery,
  useCreateCategoryMutation,
  useUpdateCategoryMutation,
  useDeleteCategoryMutation,
} = userEndpoints;
