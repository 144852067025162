import { UploadOutlined } from "@ant-design/icons";
import { Button, Col, Form, Image, Row, Select, Upload, message } from "antd";
import { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useNavigate } from "react-router-dom";
import { imgbbApiKey } from "../../../App";
import { BreadCrumb } from "../../../utils/components/BreadCrumb";
import {
  FormInput,
  NumberInput,
  TextAreInput,
} from "../../../utils/form/FormInputs";
import { useGetCategoriesQuery } from "../../categories/api/categoryEndpoints";
import {
  IPorductValue,
  useCreatePorductMutation,
} from "../api/productEndpoint";

type Props = {};

function AddProduct({}: Props) {
  const [form] = Form.useForm();
  const [details, setDetails] = useState<string>("");

  const { data: categories } = useGetCategoriesQuery("");
  const [createProduct, { isLoading, isSuccess, isError }] =
    useCreatePorductMutation();

  const navigate = useNavigate();

  useEffect(() => {
    if (isSuccess) {
      form.resetFields();
      navigate("/products");
      message.success("Product created successfully");
    } else if (isError) {
      message.error("Someting went wrong!");
    }
  }, [isLoading]);

  // Watch the fields
  const discountPercent = Form.useWatch("off_discount", form);
  const regularPrice = Form.useWatch("regular_price", form);

  // Calculate offer price
  const offerPrice =
    regularPrice && discountPercent
      ? regularPrice - (regularPrice * discountPercent) / 100
      : null;

  useEffect(() => {
    form.setFieldValue("offer_price", offerPrice);
  }, [offerPrice]);

  const categoryOption = categories?.map((item) => {
    return { value: item.id, label: item.name };
  });

  const handleDetailsChange = (value: string) => {
    setDetails(value);
  };

  const [imagePreview, setImagePreview] = useState<string[]>([]);
  const [selectedFile, setSelectedFile] = useState<any[]>([]);

  const handlePreview = (file: any) => {
    const reader = new FileReader() as any;
    reader.onloadend = () => {
      setImagePreview((imagePreview) => [
        ...imagePreview,
        reader.result as string,
      ]);
    };
    reader.readAsDataURL(file);
    setSelectedFile((selectedFile) => [...selectedFile, file]);
  };

  const uploadProps = {
    onRemove: (file: any) => {
      const index = selectedFile.indexOf(file);

      const newFileList = selectedFile.slice();
      newFileList.splice(index, 1);
      setSelectedFile(newFileList);

      const newPreviewList = imagePreview.slice();
      newPreviewList.splice(index, 1);
      setImagePreview(newPreviewList);
    },

    beforeUpload: (file: any) => {
      handlePreview(file);
      return false;
    },
  };

  const handleUploadImgBB = async () => {
    if (!selectedFile || selectedFile.length === 0) {
      message.error("Please select images first!");
      return;
    }

    const formData = new FormData();
    const uploadedImageUrls: { url: string }[] = [];

    try {
      for (const file of selectedFile) {
        formData.append("image", file);

        const response = await fetch(
          `https://api.imgbb.com/1/upload?key=${imgbbApiKey}`,
          {
            method: "POST",
            body: formData,
          }
        );

        const data = await response.json();
        if (data.success) {
          uploadedImageUrls.push({ url: data.data.url });
        } else {
          message.error(`Image upload failed for ${file.name}`);
        }

        formData.delete("image");
      }

      return uploadedImageUrls;
    } catch (error) {
      console.error("Error uploading images:", error);
      message.error("Error uploading images!");
    }
  };

  // HANDLE CREATE PRODUCT
  const handleAddOrUpdate = async (values: IPorductValue) => {
    const images = (await handleUploadImgBB()) as { url: string }[];

    await createProduct({ ...values, details, images });
  };

  return (
    <div>
      <BreadCrumb title={["Add Product"]} />
      <Form
        form={form}
        onFinish={handleAddOrUpdate}
        layout="vertical"
        style={{ background: "#7a7a7a34", padding: 20, borderRadius: 10 }}
      >
        <Row gutter={24}>
          <Col lg={8}>
            <Form.Item label="Select category" name={"category_id"}>
              <Select
                showSearch
                placeholder="Select category"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={categoryOption}
              />
            </Form.Item>
          </Col>

          <FormInput label="Product name" name="name" required size={8} />
          <NumberInput label="Off %" name="off_discount" size={8} />
          <NumberInput
            label="Regular price"
            name="regular_price"
            required
            size={8}
          />
          <NumberInput
            label="Offer price"
            name="offer_price"
            required
            size={8}
          />
          <TextAreInput label="Description" name="description" size={16} />
        </Row>

        <Upload {...uploadProps} maxCount={5}>
          <Button icon={<UploadOutlined />}>Select Image</Button>
        </Upload>

        {imagePreview?.length ? (
          imagePreview?.map((item) => {
            return (
              <Image
                src={item}
                alt="Preview"
                style={{ width: "auto", height: "80px", margin: 2 }}
              />
            );
          })
        ) : (
          <></>
        )}

        <Form.Item
          style={{ marginTop: 15, height: 220 }}
          label="Description"
          required
          rules={[{ required: true, message: "Please enter a description" }]}
        >
          <ReactQuill
            style={{ height: 150 }}
            value={details}
            onChange={handleDetailsChange}
            placeholder="Enter the description"
            modules={{
              toolbar: [
                [{ header: "1" }, { header: "2" }, { font: [] }],
                [{ list: "ordered" }, { list: "bullet" }],
                ["bold", "italic", "underline", "strike"],
                [{ align: [] }],
                ["link", "image"],
                ["clean"],
              ],
            }}
          />
        </Form.Item>

        <Row justify={"end"} style={{ marginTop: 15 }}>
          <Button type="primary" htmlType="submit">
            Add Product
          </Button>
        </Row>
      </Form>
    </div>
  );
}

export default AddProduct;
