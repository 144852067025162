import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Form, Input } from "antd";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setUser } from "../../../redux/api/authSlice";
import { useLoginUserMutation } from "../../../redux/features/usersEndpoints";
import { ILoginValue } from "../interface/auth.interface";

const LoginForm = () => {
  const [loginUser, { isSuccess, data }] = useLoginUserMutation();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (isSuccess && data?.data?.token) {
      dispatch(setUser(data?.data));
      localStorage.setItem("token", data?.data?.token);

      navigate("/");
    }
  }, [isSuccess]);

  const onFinish = (values: ILoginValue) => {
    console.log("Received values:", values);
    loginUser(values);
  };

  return (
    <div className="h-full flex-center">
      <div
        style={{
          width: "400px",
          margin: "auto",
          padding: "20px",
          backgroundColor: "#F5F5F7",
          borderRadius: 10,
        }}
      >
        <h2 style={{ textAlign: "center" }}>Login</h2>
        <Form
          name="login_form"
          initialValues={{ remember: true }}
          onFinish={onFinish}
        >
          <Form.Item
            name="username"
            rules={[{ required: true, message: "Please enter your username!" }]}
          >
            <Input prefix={<UserOutlined />} placeholder="Username" />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[{ required: true, message: "Please enter your password!" }]}
          >
            <Input.Password prefix={<LockOutlined />} placeholder="Password" />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
              Login
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default LoginForm;
