// src/commonApi.ts

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { ILoginData, IResponse } from "../../utils/interface/common.interface";

// const baseUrl = "http://localhost:4444";
const baseUrl = "https://api.cosmelic.com";

export const api = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["Post", "User", "products", "category", "order"],
  endpoints: (builder) => ({
    getInitialToken: builder.query<IResponse<ILoginData>, void>({
      query: () => ({
        url: "users/refresh-token",
      }),
    }),
  }),
});

export const { useGetInitialTokenQuery } = api;
