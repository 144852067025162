import React from "react";

const DashboardHome: React.FC = () => {
  return (
    <div>
      <img
        src="https://penpath.com/wp-content/uploads/2020/12/Screen-Shot-2020-12-02-at-6.05.01-PM.png"
        alt=""
        style={{ width: "100%" }}
      />
    </div>
  );
};

export default DashboardHome;
