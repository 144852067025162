import { ILoginValue } from "../../features/auth/interface/auth.interface";
import { ILoginData, IResponse } from "../../utils/interface/common.interface";
import { api } from "../api/api";

export const userEndpoints = api.injectEndpoints({
  endpoints: (builder) => ({
    getUsers: builder.query<any, void>({
      query: () => "/api/v1/configuration/user/all",
    }),

    loginUser: builder.mutation<IResponse<ILoginData>, ILoginValue>({
      query: (body) => ({
        url: "/users/login",
        method: "POST",
        body,
      }),
    }),
  }),
});

export const { useGetUsersQuery, useLoginUserMutation } = userEndpoints;
