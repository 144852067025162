import { ConfigProvider, Layout } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import { selectUser } from "../redux/api/authSlice";
import { FooterComponent } from "./FooterComponent";
import { HeaderComponent } from "./Header";
import { Sidebar } from "./Sidebar";

const AppLayout: React.FC = () => {
  const navigate = useNavigate();
  const { user } = useSelector(selectUser);

  if (!user.user) {
    navigate("/login");
  }

  const [isDarkMode, setIsDarkMode] = useState(false);

  const toggleTheme = (checked: boolean) => {
    setIsDarkMode(checked);
  };

  useEffect(() => {
    if (isDarkMode) {
      document.body.classList.add("dark-mode");
    } else {
      document.body.classList.remove("dark-mode");
    }
  }, [isDarkMode]);

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: isDarkMode ? "#177ddc" : "#1890ff",
          colorBgLayout: isDarkMode ? "#141414" : "#ffffff",
          colorText: isDarkMode ? "#ffffff" : "#000000",
        },
      }}
    >
      <Layout style={{ minHeight: "100vh" }}>
        <Sidebar />
        <Layout>
          <HeaderComponent
            isDarkMode={isDarkMode}
            setIsDarkMode={setIsDarkMode}
            toggleTheme={toggleTheme}
          />

          <Layout.Content style={{ margin: "0 16px" }}>
            <Outlet />
          </Layout.Content>

          <FooterComponent />
        </Layout>
      </Layout>
    </ConfigProvider>
  );
};

export default AppLayout;
