import { Modal, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import React from "react";
import { useOrderItemsQuery } from "../api/orderEndpoints";
import { IOrderItems } from "../interface/order.interface";

interface OrderItemsModalProps {
  visible: boolean;
  orderId: number | null;
  onCancel: () => void;
}

const OrderItemsModal: React.FC<OrderItemsModalProps> = ({
  visible,
  orderId,
  onCancel,
}) => {
  const { data: itemsData, isLoading: isItemsLoading } = useOrderItemsQuery(
    orderId!,
    {
      skip: !orderId,
    }
  );

  const columns: ColumnsType<IOrderItems> = [
    {
      title: "Product Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Image",
      dataIndex: "image_url",
      key: "image_url",
      render: (text: string) => (
        <img src={text} alt="Product" style={{ width: 50, height: 50 }} />
      ),
    },
    {
      title: "Quantity",
      dataIndex: "oi_qty",
      key: "oi_qty",
    },
    {
      title: "Regular Price",
      dataIndex: "oi_regulr_price",
      key: "oi_regulr_price",
    },
    {
      title: "Offer Price",
      dataIndex: "oi_offer_price",
      key: "oi_offer_price",
    },
  ];

  return (
    <Modal
      title="Order Items"
      visible={visible}
      onCancel={onCancel}
      footer={null}
      width={800}
    >
      <Table
        dataSource={itemsData?.data || []}
        columns={columns}
        loading={isItemsLoading}
        rowKey="name"
        pagination={false}
      />
    </Modal>
  );
};

export default OrderItemsModal;
