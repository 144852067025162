import { Button, Image, Modal, Row, Space, Table, message } from "antd";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { BreadCrumb } from "../../../utils/components/BreadCrumb";
import { FormInput } from "../../../utils/form/FormInputs";
import {
  IPorductValue,
  useDeletePorductMutation,
  useGetProductsQuery,
} from "../api/productEndpoint";

const ProductPage: React.FC = () => {
  const [search, setSearch] = useState("");

  const { data: productResponse, refetch } = useGetProductsQuery(
    `search=${search}`
  );

  const [deleteProduct] = useDeletePorductMutation();

  const handleDelete = async (id: number) => {
    Modal.confirm({
      title: "Are you sure you want to delete this product?",
      content: "This action cannot be undone.",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        await deleteProduct(id);
        message.success("Product deleted successfully!");
        refetch();
      },
    });
  };

  const columns = [
    {
      title: "SL",
      dataIndex: "id",
      key: "id",
      render: (curr: number, data: any, index: number) => (
        <span>{index + 1}</span>
      ),
    },
    {
      title: "Image",
      dataIndex: "image_url",
      key: "image_url",
      render: (text: string) => (
        <Image src={text} alt="Product" style={{ width: 50, height: 50 }} />
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Category",
      dataIndex: "category_name",
      key: "category_name",
    },
    {
      title: "Off %",
      dataIndex: "off_discount",
      key: "off_discount",
    },
    {
      title: "Regular Price",
      dataIndex: "regular_price",
      key: "regular_price",
    },
    {
      title: "Offer Price",
      dataIndex: "offer_price",
      key: "offer_price",
    },

    {
      title: "Actions",
      key: "actions",
      render: (text: string, record: IPorductValue) => (
        <Space>
          <Link to={`/product-edit/${record.id}`}>
            <Button size="small" type="primary" style={{ marginRight: 8 }}>
              Edit
            </Button>
          </Link>
          <Button
            size="small"
            onClick={() => handleDelete(record.id as number)}
            danger
          >
            Delete
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <BreadCrumb title={["Products"]} />
      <Row justify={"space-between"} gutter={12}>
        <Link to="/product-add">
          <Button type="primary">Add Product</Button>
        </Link>

        <FormInput
          onChange={(value) => setSearch(value as string)}
          placeholder="Search here..."
        />
      </Row>

      <Table
        bordered
        dataSource={productResponse?.data as any[]}
        columns={columns}
        rowKey="id"
        style={{ marginTop: 16 }}
      />
    </div>
  );
};

export default ProductPage;
