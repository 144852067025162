import { DesktopOutlined, PieChartOutlined } from "@ant-design/icons";
import { Menu, MenuProps } from "antd";
import Sider from "antd/es/layout/Sider";
import React, { useState } from "react";
import { Link } from "react-router-dom";

type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[]
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
  } as MenuItem;
}

const items: MenuItem[] = [
  getItem(<Link to={"/"}>Dashboard</Link>, "1", <PieChartOutlined />),
  getItem(<Link to={"/category"}>Category</Link>, "2", <DesktopOutlined />),
  getItem(<Link to={"/products"}>Products</Link>, "3", <DesktopOutlined />),
  getItem(<Link to={"/orders"}>Orders</Link>, "4", <DesktopOutlined />),
];

export const Sidebar = () => {
  const [collapsed, setCollapsed] = useState(false);

  return (
    <Sider
      collapsible
      collapsed={collapsed}
      onCollapse={(value) => setCollapsed(value)}
    >
      <div
        className="demo-logo-vertical"
        style={{
          background: "#fff",
          margin: 15,
          padding: 15,
          borderRadius: 10,
        }}
      >
        Logo
      </div>
      <Menu
        theme="dark"
        defaultSelectedKeys={["1"]}
        mode="inline"
        items={items}
      />
    </Sider>
  );
};
