import {
  Button,
  Popconfirm,
  Row,
  Select,
  Spin,
  Table,
  Tag,
  message,
} from "antd";
import { ColumnsType } from "antd/es/table";
import React, { useState } from "react";
import { BreadCrumb } from "../../../utils/components/BreadCrumb";
import {
  useDeleteOrderMutation,
  useGetOrdersQuery,
  useUpdateOrderMutation,
} from "../api/orderEndpoints";
import OrderItemsModal from "../components/OrderItemsModal";
import { IOrderData } from "../interface/order.interface";
const { Option } = Select;

const OrdersPage: React.FC = () => {
  const [status, setStatus] = useState<
    "PENDING" | "APPROVED" | "REJECTED" | "DELIVERED" | "ALL"
  >("PENDING");
  const [selectedOrderId, setSelectedOrderId] = useState<number | null>(null);
  const [isItemsModalVisible, setItemsModalVisible] = useState(false);

  // Fetch all orders
  const { data: ordersData, isLoading: isOrdersLoading } = useGetOrdersQuery(
    `status=${status}`
  );

  // Delete order
  const [deleteOrder, { isLoading: isDeleting }] = useDeleteOrderMutation();

  // Update order status
  const [updateOrder, { isLoading: isUpdating }] = useUpdateOrderMutation();

  // Handle delete order
  const handleDeleteOrder = async (orderId: number) => {
    try {
      await deleteOrder(orderId).unwrap();
      message.success("Order deleted successfully");
    } catch (error) {
      message.error("Failed to delete order");
    }
  };

  // Handle order status update
  const handleUpdateOrder = async (
    orderId: number,
    status: "PENDING" | "APPROVED" | "REJECTED" | "DELIVERED"
  ) => {
    try {
      await updateOrder({ id: orderId, body: { o_status: status } }).unwrap();
      message.success("Order status updated successfully");
    } catch (error) {
      message.error("Failed to update order status");
    }
  };

  // Define table columns
  const columns: ColumnsType<IOrderData> = [
    {
      title: "SL",
      dataIndex: "o_id",
      key: "o_id",
      render: (_: any, data: any, index: number) => <>{index + 1}</>,
    },
    {
      title: "Customer Name",
      dataIndex: "o_cus_name",
      key: "o_cus_name",
    },
    {
      title: "Mobile",
      dataIndex: "o_cus_mobile",
      key: "o_cus_mobile",
    },
    {
      title: "City",
      dataIndex: "o_cus_city",
      key: "o_cus_city",
    },
    {
      title: "Status",
      dataIndex: "o_status",
      key: "o_status",
      render: (status) => {
        const color =
          status === "DELIVERED"
            ? "green"
            : status === "PENDING"
            ? "orange"
            : "red";
        return <Tag color={color}>{status}</Tag>;
      },
    },
    {
      title: "Total",
      dataIndex: "o_net_total",
      key: "o_net_total",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <>
          <Select
            size="small"
            defaultValue={record.o_status}
            onChange={(value) =>
              handleUpdateOrder(
                record.o_id,
                value as "APPROVED" | "REJECTED" | "DELIVERED"
              )
            }
          >
            <Option value="APPROVED">Approve</Option>
            <Option value="REJECTED">Reject</Option>
            <Option value="DELIVERED">Deliver</Option>
          </Select>
          <Button
            type="link"
            onClick={() => {
              setSelectedOrderId(record.o_id);
              setItemsModalVisible(true);
            }}
          >
            View Items
          </Button>
          <Popconfirm
            title="Are you sure to delete this order?"
            onConfirm={() => handleDeleteOrder(record.o_id)}
            okText="Yes"
            cancelText="No"
          >
            <Button type="link" danger>
              Delete
            </Button>
          </Popconfirm>
        </>
      ),
    },
  ];

  const handleChange = (
    value: "PENDING" | "APPROVED" | "REJECTED" | "DELIVERED" | "ALL"
  ) => {
    setStatus(value);
  };

  if (isDeleting || isUpdating) {
    return (
      <Row
        justify="center"
        align="middle"
        style={{ position: "fixed", top: "50%", left: "50%" }}
      >
        <Spin />
      </Row>
    );
  }

  return (
    <div>
      <Row justify={"space-between"} align={"middle"}>
        <BreadCrumb title={["Orders"]} />
        <Select value={status} style={{ width: 200 }} onChange={handleChange}>
          <Option value="ALL">All</Option>
          <Option value="PENDING">Pending</Option>
          <Option value="APPROVED">Approved</Option>
          <Option value="REJECTED">Rejected</Option>
          <Option value="DELIVERED">Delivered</Option>
        </Select>
      </Row>

      <Table
        dataSource={ordersData?.data || []}
        columns={columns}
        loading={isOrdersLoading}
        rowKey="o_id"
        pagination={false}
        bordered
      />
      {/* Modal for displaying order items */}
      <OrderItemsModal
        visible={isItemsModalVisible}
        orderId={selectedOrderId}
        onCancel={() => setItemsModalVisible(false)}
      />
    </div>
  );
};

export default OrdersPage;
