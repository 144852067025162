// src/features/auth/authSlice.ts

import { createSlice } from "@reduxjs/toolkit";
import { ILoginData } from "../../utils/interface/common.interface";
import { RootState } from "../store/store";

interface AuthState {
  user: ILoginData | null;
}

const initialState: AuthState = {
  user: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    clearUser: (state) => {
      state.user = null;
      localStorage.removeItem("token");
    },
  },
});

export const { setUser, clearUser } = authSlice.actions;
export default authSlice.reducer;

export const selectUser = (state: RootState) => state;
