import { QuestionCircleOutlined, UploadOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Form,
  Image,
  Modal,
  Popconfirm,
  Row,
  Table,
  Upload,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import { FormInput } from "../../../utils/form/FormInputs";
import {
  useCreateCategoryMutation,
  useDeleteCategoryMutation,
  useGetCategoriesQuery,
  useUpdateCategoryMutation,
} from "../api/categoryEndpoints";
import { imgbbApiKey } from "../../../App";

const CategoryPage: React.FC = () => {
  // states
  const [selectedFile, setSelectedFile] = useState(null);

  const [imagePreview, setImagePreview] = useState("");
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [currentCategory, setCurrentCategory] = useState<any>(null);

  const { data: categories, refetch } = useGetCategoriesQuery("");
  const [
    createCategory,
    { isLoading: createLoading, isSuccess: createSuccess, isError: createErr },
  ] = useCreateCategoryMutation();
  const [
    updateCategory,
    { isLoading: updateLoading, isSuccess: updateSuccess, isError: updateErr },
  ] = useUpdateCategoryMutation();
  const [
    deleteCategory,
    { isLoading: deleteLoading, isSuccess: deleteSuccess, isError: deleteErr },
  ] = useDeleteCategoryMutation();

  const [form] = Form.useForm();

  useEffect(() => {
    if (updateSuccess) {
      setEditMode(false);
    }

    if (createSuccess || updateSuccess || deleteSuccess) {
      form.resetFields();
      refetch();
      setVisible(false);
      setImagePreview("");
    } else if (createErr || updateErr || deleteErr) {
      message.error("Failed to save category!");
    }
  }, [createLoading, updateLoading, deleteLoading]);

  // HANDLE PREVIEW IMAGE
  const handlePreview = (file: any) => {
    const reader = new FileReader() as any;
    reader.onloadend = () => {
      setImagePreview(reader.result);
    };
    reader.readAsDataURL(file); // Convert image to base64 string for preview
    setSelectedFile(file); // Set the selected file for upload
  };

  // Function to handle file upload to ImgBB
  const handleUpload = async () => {
    if (!selectedFile) {
      message.error("Please select an image first!");
      return;
    }

    setLoading(true);
    const formData = new FormData();
    formData.append("image", selectedFile);

    try {
      const response = await fetch(
        `https://api.imgbb.com/1/upload?key=${imgbbApiKey}`,
        {
          method: "POST",
          body: formData,
        }
      );

      const data = await response.json();
      if (data.success) {
        return data.data.url;
      } else {
        message.error("Image upload failed!");
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      message.error("Error uploading image!");
    } finally {
      setLoading(false);
    }
  };

  const uploadProps = {
    beforeUpload: (file: any) => {
      handlePreview(file); // Show image preview before upload
      return false; // Prevent automatic upload by Ant Design
    },
  };

  const handleAddOrUpdate = async (values: any) => {
    if (editMode) {
      await updateCategory({
        pay: { name: values.name, img: await handleUpload() },
        id: currentCategory.id,
      });
    } else {
      await createCategory({ name: values.name, img: await handleUpload() });
    }
  };

  const handleDelete = async (id: number) => {
    await deleteCategory(id);
    message.success("Category deleted successfully!");
    refetch(); // Refresh the category list
  };

  const handleEdit = (category: any) => {
    setCurrentCategory(category);
    setEditMode(true);
    setVisible(true);
    setImagePreview(category.img);
    form.setFieldsValue({ name: category.name, img: category.img });
  };

  const columns = [
    {
      title: "SL",
      dataIndex: "id",
      key: "id",
      render: (_: any, data: any, index: number) => <>{index + 1} </>,
    },

    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Image",
      dataIndex: "img",
      key: "img",
      render: (text: string) => (
        <Image src={text} alt="Category" style={{ width: 50, height: 50 }} />
      ),
    },
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Actions",
      key: "actions",
      render: (text: string, record: any) => (
        <>
          <Button
            onClick={() => handleEdit(record)}
            type="primary"
            style={{ marginRight: 8 }}
            size="small"
          >
            Edit
          </Button>
          <Popconfirm
            onConfirm={() => handleDelete(record.id)}
            title="Delete the task"
            description="Are you sure to delete this task?"
            icon={<QuestionCircleOutlined style={{ color: "red" }} />}
          >
            <Button size="small" danger>
              Delete
            </Button>
          </Popconfirm>
        </>
      ),
    },
  ];

  return (
    <div>
      <h2>Category Management</h2>
      <Button type="primary" onClick={() => setVisible(true)}>
        Add Category
      </Button>
      <Table
        dataSource={categories}
        columns={columns}
        rowKey="id"
        style={{ marginTop: 16 }}
        pagination={false}
      />

      <Modal
        title={editMode ? "Update Category" : "Add Category"}
        visible={visible}
        onCancel={() => {
          setVisible(false);
          setEditMode(false);
          setImagePreview("");
          form.resetFields();
        }}
        footer={null}
      >
        <Form form={form} onFinish={handleAddOrUpdate} layout="vertical">
          <Row>
            <FormInput size={24} label="Category Name" name="name" required />

            <Col lg={12} sm={12}>
              <Form.Item
                label="Category Image"
                name="img"
                rules={[
                  {
                    required: true,
                    message: "Please input the category name!",
                  },
                ]}
              >
                <Upload maxCount={1} {...uploadProps}>
                  <Button icon={<UploadOutlined />}>Select Image</Button>
                </Upload>
              </Form.Item>
            </Col>

            <Col lg={12} sm={12}>
              {imagePreview ? (
                <Image
                  src={imagePreview}
                  alt="Preview"
                  style={{ width: "80px", height: "auto" }}
                />
              ) : (
                <></>
              )}
            </Col>
          </Row>

          <Row justify={"end"}>
            <Button type="primary" htmlType="submit">
              {editMode ? "Update Category" : "Add Category"}
            </Button>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};

export default CategoryPage;
