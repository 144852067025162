import { Breadcrumb } from 'antd'
import { Link } from 'react-router-dom'

type Props = { title: string[] }

export const BreadCrumb = ({ title }: Props) => {
  return <Breadcrumb style={{ margin: '16px 0' }}>
    <Breadcrumb.Item ><Link to={'/'}>Dashboard</Link> </Breadcrumb.Item>
    {title?.map(item => <Breadcrumb.Item key={item}>{item}</Breadcrumb.Item>)}

  </Breadcrumb>
}