import { api } from "../../../redux/api/api";

export interface IPorductData {
  id?: number;
  name: string;
  category_id: number;
  regular_price: number;
  offer_price: number;
  images: string[];
  off_discount: number;
  rating_value: number;
  total_reviews: number;
  details: string;
}

export interface IPorductValue {
  id?: number;
  name: string;
  category_id: number;
  regular_price: number;
  offer_price: number;
  description: string;
  images: { url: string }[];
  off_discount: number;
  details: string;
}

export interface IResponse<T> {
  success: boolean;
  message: string;
  data: T;
}

export const userEndpoints = api.injectEndpoints({
  endpoints: (builder) => ({
    getProducts: builder.query<IResponse<IPorductData[]>, string>({
      query: (query) => `/products?${query}`,
      providesTags: ["products"],
    }),

    getProductForEdit: builder.query<IResponse<IPorductValue>, number>({
      query: (id) => `/products/for-edit/${id}`,
    }),

    createPorduct: builder.mutation<any, IPorductValue>({
      query: (body) => ({
        url: "/products",
        method: "POST",
        body,
      }),
      invalidatesTags: ["products"],
    }),

    updateProduct: builder.mutation<any, { pay: IPorductValue; id: number }>({
      query: (body) => ({
        url: `/products/${body.id}`,
        method: "PUT",
        body: body.pay,
      }),
      invalidatesTags: ["products"],
    }),

    updateProductstatus: builder.mutation<
      any,
      {
        pay: {
          is_active: number;
        };
        id: number;
      }
    >({
      query: (body) => ({
        url: `/products/${body.id}`,
        method: "PATCH",
        body: body.pay,
      }),
      invalidatesTags: ["products"],
    }),

    deletePorduct: builder.mutation<any, number>({
      query: (id) => ({
        url: `/products/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["products"],
    }),
  }),
});

export const {
  useGetProductsQuery,
  useCreatePorductMutation,
  useUpdateProductMutation,
  useUpdateProductstatusMutation,
  useDeletePorductMutation,
  useGetProductForEditQuery,
} = userEndpoints;
