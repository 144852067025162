import "antd/dist/reset.css"; // For Ant Design v5
import "./App.css";

import { Row, Spin } from "antd";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import LoginForm from "./features/auth/pages/LoginForm";
import CategoryTable from "./features/categories/pages/Categories";
import DashboardHome from "./features/dashboard/pages/Dashboard";
import OrdersPage from "./features/orders/pages/OrderPage";
import AddProduct from "./features/products/pages/AddProduct";
import EditProduct from "./features/products/pages/EditProduct";
import ProductPage from "./features/products/pages/Products";
import AppLayout from "./layout/AppLayout";
import { useGetInitialTokenQuery } from "./redux/api/api";
import { setUser } from "./redux/api/authSlice";

export const imgbbApiKey = "dbfede5774c86fec2f379147926b2e7f";

const App = () => {
  const { data, isSuccess, isLoading } = useGetInitialTokenQuery();

  const [fetchInitial, setFetchInitial] = useState(true);

  const newUser = data?.data;

  const dispatch = useDispatch();

  useEffect(() => {
    if (newUser) {
      dispatch(setUser(newUser));
    }

    setFetchInitial(false);
  }, [isSuccess, isLoading]);

  if (fetchInitial) {
    return (
      <Row
        justify="center"
        align="middle"
        style={{ position: "fixed", top: "50%", left: "50%" }}
      >
        <Spin />
      </Row>
    );
  }

  return (
    <Router>
      <Routes>
        <Route
          path="/login"
          element={newUser ? <Navigate to={"/"} /> : <LoginForm />}
        />
        <Route path="/" element={<AppLayout />}>
          <Route index element={<DashboardHome />} />
          <Route path="/category" element={<CategoryTable />} />
          <Route path="/products" element={<ProductPage />} />
          <Route path="/product-edit/:id" element={<EditProduct />} />
          <Route path="/product-add" element={<AddProduct />} />
          <Route path="/orders" element={<OrdersPage />} />
        </Route>
      </Routes>
    </Router>
  );
};

export default App;
