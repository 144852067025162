import { api } from "../../../redux/api/api";
import { IResponse } from "../../../utils/interface/common.interface";
import { IOrderData, IOrderItems } from "../interface/order.interface";

export const userEndpoints = api.injectEndpoints({
  endpoints: (builder) => ({
    // ALL ORDERS
    getOrders: builder.query<IResponse<IOrderData[]>, string>({
      query: (query) => `/products/order?${query}`,
      providesTags: ["order"],
    }),

    // ALL ORDERS
    orderItems: builder.query<IResponse<IOrderItems[]>, number>({
      query: (id) => `/products/order-item/${id}`,
      providesTags: ["order"],
    }),

    // DELETE ORDER
    deleteOrder: builder.mutation<any, number>({
      query: (id) => ({
        url: `/products/order/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["order"],
    }),

    // UPDATE ORDER
    updateOrder: builder.mutation<
      any,
      {
        body: { o_status: "PENDING" | "APPROVED" | "REJECTED" | "DELIVERED" };
        id: number;
      }
    >({
      query: (pay) => ({
        url: `/products/order/${pay.id}`,
        method: "PATCH",
        body: pay.body,
      }),
      invalidatesTags: ["order"],
    }),
  }),
});

export const {
  useGetOrdersQuery,
  useDeleteOrderMutation,
  useUpdateOrderMutation,
  useOrderItemsQuery,
} = userEndpoints;
