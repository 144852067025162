import { Col, Form, Input, InputNumber } from "antd";
import { NamePath } from "antd/es/form/interface";
import TextArea from "antd/es/input/TextArea";

type Props = {
  readonly?: boolean;
  color?: "green" | "red" | "gray";
  disabled?: boolean;
  help?: string;
  label?: string;
  name?: NamePath;
  maxAmount?: number;
  min?: string;
  max?: string | number;
  offset?: number;
  onChange?: React.Dispatch<React.SetStateAction<number | string>>;
  onBlur?: (value: any) => void;
  onPressEnter?: (e: any) => void;
  onClick?: (e: any) => void;
  placeholder?: string;
  readOnly?: boolean;
  required?: boolean;
  size?: number;
  small?: boolean;
  style?: React.CSSProperties;
  validateStatus?: boolean;
  className?: string;
  hidden?: boolean;
};

export const FormInput = ({
  disabled,
  name,
  label,
  required,
  size,
  onBlur,
  onChange,
  validateStatus,
  help,
  small,
  offset,
  max,
  className,
  hidden,
  readonly,
  placeholder,
}: Props) => {
  return (
    <Col
      hidden={hidden || false}
      xs={24}
      lg={{ span: size || 8, offset: offset || 0 }}
    >
      <Form.Item
        name={name}
        label={label}
        validateStatus={validateStatus ? "error" : "validating"}
        help={validateStatus ? help : null}
      >
        {
          <Input
            required={required}
            max={max || undefined}
            size={small ? "small" : "middle"}
            readOnly={readonly}
            disabled={disabled}
            placeholder={placeholder || label}
            onBlur={(e) => {
              onBlur && onBlur(e.target.value);
            }}
            className={`${className ? className : ""}`}
            onChange={(e) => {
              console.log({ value: e.target.value });

              onChange && onChange(e.target.value);
            }}
          />
        }
      </Form.Item>
    </Col>
  );
};

export const NumberInput = ({
  disabled,
  name,
  label,
  required,
  size,
  onBlur,
  onChange,
  validateStatus,
  help,
  small,
  offset,
  max,
  className,
  hidden,
  readonly,
  placeholder,
}: Props) => {
  return (
    <Col
      hidden={hidden || false}
      xs={24}
      lg={{ span: size || 8, offset: offset || 0 }}
    >
      <Form.Item
        name={name}
        label={label}
        validateStatus={validateStatus ? "error" : "validating"}
        help={validateStatus ? help : null}
      >
        {
          <InputNumber
            min={0}
            style={{ width: "100%" }}
            required={required}
            max={max || undefined}
            size={small ? "small" : "middle"}
            readOnly={readonly}
            disabled={disabled}
            placeholder={placeholder || label}
            onBlur={(e) => {
              onBlur && onBlur(e.target.value);
            }}
            className={`${className ? className : ""}`}
            onChange={(e) => {
              onChange && onChange(e as any);
            }}
          />
        }
      </Form.Item>
    </Col>
  );
};

export const TextAreInput = ({
  disabled,
  name,
  label,
  required,
  size,
  onBlur,
  onChange,
  validateStatus,
  help,
  small,
  offset,
  max,
  className,
  hidden,
  readonly,
  placeholder,
}: Props) => {
  return (
    <Col
      hidden={hidden || false}
      xs={24}
      lg={{ span: size || 8, offset: offset || 0 }}
    >
      <Form.Item
        name={name}
        label={label}
        validateStatus={validateStatus ? "error" : "validating"}
        help={validateStatus ? help : null}
      >
        {
          <TextArea
            style={{ width: "100%" }}
            required={required}
            size={small ? "small" : "middle"}
            readOnly={readonly}
            disabled={disabled}
            placeholder={placeholder || label}
            onBlur={(e) => {
              onBlur && onBlur(e.target.value);
            }}
            className={`${className ? className : ""}`}
            onChange={(e) => {
              onChange && onChange(e as any);
            }}
          />
        }
      </Form.Item>
    </Col>
  );
};
